<template>
    <div class="container">
        <Y-header></Y-header>
        <div class="body-container">
            <div class="blocker"></div>
            <banner :data="bannerData" height="600px" :speed="bannerSpeed"></banner>
            <div style="height: 40px"></div>
            <center-title :text="title" bold font-size="24" position="center"></center-title>
            <center-title :text="subtitle" bold font-size="16" position="center"></center-title>

            <div class="richText" v-html="richText"></div>
            <div class="case-applyWare-box">
                <p class="title">{{title}}</p>
                <div style="display: flex">
                    <div class="text-box">
                        <span class="ware-title">应用硬件:</span>
                        <ul style="min-height: 200px">
                            <li v-for="(item,i) in this.hardware" :key="i">{{item}}</li>
                        </ul>
                    </div>
                    <div class="text-box">
                        <span class="ware-title">应用软件:</span>
                        <ul style="min-height: 200px">
                            <li v-for="(item,i) in this.software" :key="i">{{item}}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="button-box">
                <a-button :disabled="disabledPev" @click="pev">上一篇</a-button>
                <a-button :disabled="disabledNext" @click="next">下一篇</a-button>
                <a-button @click="back">返回列表</a-button>
            </div>
        </div>
        <Y-footer></Y-footer>
        <a-back-top/>
    </div>
</template>

<script>
import banner from "@/components/banner.vue";
import centerTitle from "@/components/centerTitle.vue";
import footer from "@/components/footer.vue";
import header from "@/components/header.vue";
import handleRequest from "@/utils/handleRequest";
import {bannerData, projectCase} from "@/api/apis";
import tools from "@/utils/tools";

export default {
    name: "caseDetail",
    components: {
        centerTitle,
        banner,
        'Y-footer':footer,
        'Y-header':header,
    },
    data() {
        return {
            bannerData:[],
            bannerSpeed:2000,
            title:'',
            subtitle:'',
            richText:'',
            hardware:[],
            software:[],
            id:'',
            listData:[],
            curIndex:0,
            disabledNext:false,
            disabledPev:false,

        }
    },
    mounted() {
        this.getBannerData();
        this.getListData();
        this.id = this.$route.query.id

    },
    methods: {
        async getBannerData(){
            const data = handleRequest(await bannerData({type:'项目案例',state:1}))
            let banner = data.content
            banner.forEach(item=>{
                item.img = JSON.parse(item.img)[0]
            })
            this.bannerSpeed = (banner[0]?.second*1000) || this.bannerSpeed
            this.bannerData = banner

        },
        async getListData(){
            const data = handleRequest(await projectCase({}))
            let ListData = data.content
            ListData.forEach(item=>{
                item.hardware = JSON.parse(item.hardware)
                item.software = JSON.parse(item.software)
            })
            this.listData = ListData
            this.listData.forEach(item=>{
                if(this.id == item.id){
                    this.title = item.name
                    this.richText = item.introduce
                    this.hardware = item.hardware
                    this.software = item.software
                }
            })
            this.getCurCaseIndex();

        },
        getCurCaseIndex(){
            this.listData.forEach((item,i)=>{
                console.log(item)
                if(this.id == item.id){
                    this.curIndex = i
                }
            })
            this.disabledPev = this.curIndex == 0 ? true:false
            this.disabledNext = this.curIndex == this.listData.length-1 ? true:false
            console.log(this.curIndex)
        },
        pev(){
            if(this.curIndex == 0){
                this.disabledPev = true
                return
            }
            this.disabledNext = false
            this.curIndex--
            this.title = this.listData[this.curIndex].name
            this.richText = this.listData[this.curIndex].introduce
            this.hardware = this.listData[this.curIndex].hardware
            this.software = this.listData[this.curIndex].software
        },
        next(){
            if(this.curIndex == this.listData.length-1){
                this.disabledNext = true
                return
            }
            this.disabledPev = false
            this.curIndex++
            this.title = this.listData[this.curIndex].name
            this.richText = this.listData[this.curIndex].introduce
            this.hardware = this.listData[this.curIndex].hardware
            this.software = this.listData[this.curIndex].software
        },
        back(){
            this.$router.go(-1)
        },
    },
}
</script>

<style scoped lang="scss">
.container{
    .body-container{
        .blocker{
            height: 92px;
        }
        .richText{
            margin:0 320px;
            padding:20px 30px;
            :not(not){
                //text-align: center;
            }
        }
        .case-applyWare-box{
            margin:0 320px;
            padding:20px 30px;
            .text-box{
                display: inline-block;
                margin-right: 120px;
            }
            .title{
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 22px;
                color: #3769FE;
                line-height: 22px;
                text-align: left;
                font-style: normal;
            }
            .ware-title{
                display: block;
                margin: 10px 0;
                font-size: 16px;
            }
            ul li{
                margin: 10px 0;
                list-style: none;
                background: url("../assets/check.png") no-repeat 0 4px;
                background-size: 15px 15px;
                text-indent: 20px;
            }

        }
        .button-box{
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            button{
                margin: 0 40px;
            }
        }
    }
}
</style>
